<template>
  <div class="pc-group-checkbox-container flex flex-col gap-[14px]">
    <div class="flex gap-[10px] cursor-pointer justify-center items-center" @click="sectionIsOpen = !sectionIsOpen">
      <p
          class="text-[18px] text-darkDark flex-grow m-0 leading-[32px]"
          :style="{fontFamily: sectionIsOpen ? 'Suit-Bold' : 'Suit', color: sectionIsOpen ? '#A8221A' : '#303030'}"
      >{{title}}</p>
      <p
          class="text-[18px] text-darkLight m-0 leading-[32px]"
          :style="{color: sectionIsOpen ? '#A8221A' : '#303030'}"
      >{{list.length}}</p>
      <div class="w-[16px] h-[16px] bg-no-repeat bg-center bg-[length:16px]" :style="{backgroundImage: `url('${sectionIsOpen ? images.closeIcon : images.openIcon}')`}" />
    </div>
    <div class="max-h-[340px] overflow-auto" v-if="sectionIsOpen">
      <div class="flex flex-col gap-[4px]" v-for="filterItem in list" v-bind:key="filterItem.key">
        <label class="winex-checkbox item py-[4px]">
          <input
            type="checkbox"
            :name="filterItem.name"
            :value="filterItem.name"
            v-model="filterItem.select"
            @change="changeFilter"
          />
          <span class="leading-[28px] font-[16px] font-[Suit-Medium]">{{ filterItem.name }}</span>
        </label>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    title: {
      default: '',
      type: String,
    },
    isOpen: {
      default: false,
      type: Boolean,
    },
    list: {
      default() {
        return [];
      },
      type: Array,
    },
    changeFilter: {
      default: () => {

      },
      type:Function
    }
  },
  created() {
    this.sectionIsOpen = this.isOpen
  },
  data() {
    return {
      sectionIsOpen: false,
      images: {
        closeIcon: require('@/assets/images/icon/accordion-close.png'),
        openIcon: require('@/assets/images/icon/accordion-open.png'),
      },
    };
  },
  methods: {

  }
};
</script>
<style scoped lang="scss">
.pc-group-checkbox-container {
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid #eeeeee;
  padding: 14px 0;

  .none {
    display: none;
  }

  .winex-dropdown {
    cursor: pointer;
    margin: 0;
    input[type='checkbox'] {
      display: none !important;
    }
    input[type='checkbox'] {
      font-size: 16px;
      height: 28px;
      line-height: 28px;
      display: inline-block;
      padding-left: 34px;
      background-repeat: no-repeat;
      background-image: url(~@/assets/images/icon/accordion-open.png);
    }
    input[type='checkbox']:checked {
      background-image: url(~@/assets/images/icon/accordion-close.png);
    }
  }

  .title-container {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding: 14px 0;

    .title-open {
      font-family: 'SUIT';
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 32px;
      justify-content: space-between;
      padding-right: 10px;
      display: flex;
      width: 100%;
    }

    .dropdown-button {
      cursor: pointer;
    }
  }

  .checkbox-container {
    display: flex;
    flex-direction: column;
    max-height: 340px;
    overflow-y: auto;
    .item {
      margin-bottom: 12px;
    }
  }

  .checkbox-container::-webkit-scrollbar {
    display: none;
  }
}
</style>
