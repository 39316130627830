<template>

  <div class="w-full h-full fixed bg-white z-10 top-0 flex flex-col left-0">
    <div class="flex justify-between pl-[16px] items-center border-b border-[#EEEEEE] flex-shrink">
      <p class="text-[16px] text-darkDark font-[Suit-Bold] m-0">Filter</p>
      <div
          @click="$emit('close')"
          class="w-[60px] h-[60px] bg-[length:16px] bg-no-repeat bg-center"
          :style="{backgroundImage: `url('${require('@/assets/images/icon/icon-black-close.png')}')`}" />
    </div>

    <div class="flex-grow px-[20px] overflow-auto">
      <PcGroupCheckbox v-if="isMypage"
          :list="statusList"
          title="Status"
          :is-open="isOpenStatusFilter"
          :change-filter="changStatus"
      />

      <PcGroupCheckbox
          v-for="item in filterList"
          :key="item.name"
          :list="item.list"
          :title="item.name"
          :is-open="item.isOpen"
          :change-filter="changeFilter"
      />
    </div>
    <div class="flex-shrink border-t border-[#EEEEEE] flex py-[14px] px-[16px] gap-[10px]">
      <div
          class="cursor-pointer border border-lightLightDark rounded-[5px] h-[52px] w-full text-[16px] text-gray6 leading-[52px] text-center"
          @click="resetFilter"
      >초기화</div>
      <div
          class="cursor-pointer rounded-[5px] h-[52px] w-full text-[16px] text-white leading-[52px] text-center bg-[#A8221A] font-[Suit-Bold]"
          @click="$emit('change', {'status': statusList, 'filter': filterList, })"
      >필터적용</div>
    </div>
  </div>

</template>

<script>
// import MobileGroupCheckbox from '../checkbox/MobileGroupCheckbox.vue';
import PcGroupCheckbox from "@/components/checkbox/pcGroupCheckbox";
// import {mapState} from "vuex";

export default {
  name: 'AlertModal',
  components: {PcGroupCheckbox},
  // components: { MobileGroupCheckbox },
  props: {
    useCustom: {
      default: false,
      type: Boolean,
    },
    confirmClickBtn: {
      default: () => {},
      type: Function,
    },
    pcWidth: {
      default: 640,
    },
    isOpen: {
      default: false,
      type: Boolean,
    },
    originFilterList: {
      default: () => [],
      type: Array,
    },
    closeFilterModal: {
      default: () => {},
      type: Function,
    },
    isMypage: {
      default: false,
      type: Boolean
    },
    status: {
      default: () => [],
      type: Array
    }
  },
  /*computed: {
    filterList(){

      const list = [];

      this.originFilterList.forEach((filter, index) => {
        const items = [];
        filter.list.forEach((item, itemIndex) => {
          let temp = {
            key: item['key'],
            name: item['name'],
            select: item['select']
          };
          items[itemIndex] = temp;
        })


        list[index] = {
          isOpen: filter['isOpen'],
          list: items,
          name: filter['name']
        };
      })

      console.dir(list)

      return list;

      // console.dir(JSON.parse(JSON.stringify(this.originFilterList)))
      //
      // return JSON.parse(JSON.stringify(this.originFilterList));
      // console.dir(this.$store.state)
      // return this.$store.state.wineFilterList.wineFilterList
    }
    // this.filterList = this.originFilterList;
  },*/
  data() {
    return {
      selfButtonClass: this.buttonClass,
      mobileStyle: {},

      isOpenStatusFilter: false,


      filterList: [],
      statusList: this.status,
      /*statusList: [
        {
          key: '01',
          name: 'Listed',
          select: false,
        },
        {
          key: '02',
          name: 'Unlisted',
          select: false,
        },
        {
          key: '03',
          name: 'Redeemed',
          select: false,
        },
      ],*/

    };
  },
  created() {
    const list = [];

    this.originFilterList.forEach((filter, index) => {
      const items = [];
      filter.list.forEach((item, itemIndex) => {
        let temp = {
          key: item['key'],
          name: item['name'],
          select: item['select']
        };
        items[itemIndex] = temp;
      })


      list[index] = {
        isOpen: filter['isOpen'],
        list: items,
        name: filter['name']
      };
    })

    // console.dir(list)

    this.filterList = list;
  },
/*  mounted() {
    if (this.$isMobile()) {
      this.selfButtonClass = this.selfButtonClass + ' h48';
      this.mobileStyle = { width: '100%' };
    }
  },*/
  methods:{
    changStatus(){
      const selectStatus = [];
      this.statusList.forEach((status) => {
        if(status.select){
          selectStatus.push(status.key)
        }
      });
      this.state = selectStatus.join(',');
      // this.getWineList(1, 'pass')
    },
    changeFilter(){


    },
    resetFilter(){
      // console.dir(1234);

      this.statusList.forEach((status) => {
        status.select = false;
      })

      this.filterList.forEach((filter) => {
        filter.list.forEach((item) => {
          item.select = false;
        })
      })
    }
  }
};
</script>

